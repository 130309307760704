import React, { useState, useEffect } from 'react';
import AxiosOICStat from '../AxiosOICStat';
import { Select, Button, Row, Col, Tooltip, DatePicker, Form, Tabs, Collapse, Checkbox, Table, message, Tag, Divider, Typography } from 'antd';
import DataTable from '../components/DataTable';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import SummaryDataTable from 'components/SummaryDataTable';

const { Option } = Select;
const { RangePicker } = DatePicker;

export function DataFirstLastYear({ preSelectedCountries = [1, 43], preSelectedIndicators = [172, 395], preSelectedYears = [1995, 2005, 2015, 2020], auto_search = false }) {
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedIndicators, setSelectedIndicators] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [dateRangeFirstYear, setDateRangeFirstYear] = useState(null);
    const [dateRangeLastYear, setDateRangeLastYear] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [searchSummaryTableResults, setSearchSummaryTableResults] = useState([]);
    const [searchAggrResults, setSearhAggrResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const countriesState = useSelector((state) => state.countries);
    const indicatorsState = useSelector((state) => state.indicators);
    const unitsState = useSelector((state) => state.units);
    const categoriesState = useSelector((state) => state.categories);
    const [activeTabKey, setActiveTabKey] = useState("countries");
    const [regionMappingsData, setRegionMappingsData] = useState([]);
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [summaryTableColumns, setSummaryTableColumns] = useState([]);
    const [searchRegionResults, setSearchRegionResults] = useState([]);
    const [searchCountryResults, setSearchCountryResults] = useState([]);
    const [searchWorldResults, setSearchWorldResults] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [columns, setColumns] = useState([]);

    const renderTagPlaceholder = (omittedValues) => {
        // Sort the omitted values alphabetically based on their labels
        const sortedOmittedValues = [...omittedValues].sort((a, b) => parseInt(a.value) - parseInt(b.value));

        // Create a multiline string of all sorted omitted values
        const fullList = sortedOmittedValues.map(val => val.label).join('\n');
        return (
            <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{fullList}</span>}>
                <span>{`+${omittedValues.length} more`}</span>
            </Tooltip>
        );
    };

    useEffect(() => {
        // Assume these are the endpoints to fetch platform and mission data
        if (!auto_search) {

        }
        else {
            //handleSearch();
        }
    }, [auto_search]);

    useEffect(() => {
        // Fetch the region mappings when the component is mounted
        const fetchRegionMappings = async () => {
            try {
                const response = await AxiosOICStat.get('/region-mappings/'); // Adjust the endpoint as needed
                setRegionMappingsData(response.data); // Assuming the response body will be the array of region mappings
            } catch (error) {
                console.error('Failed to fetch region mappings:', error);
                // Handle errors, e.g., show notification
            }
        };

        fetchRegionMappings();
    }, []);

    const handleCountrySearch = () => {
        //const formattedRegions = [];
        setLoading(true)
        const firstYearOfFirst = dateRangeFirstYear[0].year()
        const lastYearOfFirst = dateRangeFirstYear[1].year()
        const firstYearOfLast = dateRangeLastYear[0].year()
        const lastYearOfLast = dateRangeLastYear[1].year()

        const firstYears = []
        firstYears.push(Number(firstYearOfLast));
        firstYears.push(Number(lastYearOfLast))
        const lastYears = []
        lastYears.push(Number(firstYearOfFirst));
        lastYears.push(Number(lastYearOfFirst))

        const yearRange = Array.from({ length: lastYearOfLast - firstYearOfFirst + 1 }, (v, i) => firstYearOfFirst + i);
        const formattedIndicators = selectedIndicators.map(indicator => Number(indicator.split(' - ')[0]));
        const formattedCountries = selectedCountries.map(country => Number(country))


       AxiosOICStat.post('/data/first-last-value',{
        countries: formattedCountries,
        indicators: formattedIndicators,
        first_year_range: firstYears,
        last_year_range: lastYears  
       }).then(res => {
            const fixedData = res.data.map(item => ({
                ...item,
                d_value_first: item.d_value_last,
                d_value_last: item.d_value_first,
                d_year_first: item.d_year_last,
                d_year_last: item.d_year_first,
            }));
            console.log(fixedData);
            setSearchResults(fixedData);
            setLoading(false);
       })


    };

    const handleRegionSearch = () => {
        message.warning("This operation may take time.")
        setLoading(true)
        const firstYearOfFirst = dateRangeFirstYear[0].year()
        const lastYearOfFirst = dateRangeFirstYear[1].year()
        const firstYearOfLast = dateRangeLastYear[0].year()
        const lastYearOfLast = dateRangeLastYear[1].year()

        const firstYears = []
        firstYears.push(Number(firstYearOfFirst));
        firstYears.push(Number(lastYearOfFirst))
        const lastYears = []
        lastYears.push(Number(firstYearOfLast));
        lastYears.push(Number(lastYearOfLast));

        //console.log(firstYears)
        //console.log(lastYears)
        //console.log(selectedRegions)
        
        const regionCodes = selectedRegions.map(region => Number(region.code))
        const formattedIndicators = selectedIndicators.map(indicator => Number(indicator.split(' - ')[0]));
        //console.log(regionCodes)
        //console.log(formattedIndicators)

        AxiosOICStat.post('/aggregations/calculate-first-last-year',{
            regions: regionCodes,
            indicators: formattedIndicators,
            first_year_range: firstYears,
            last_year_range: lastYears  
           }).then(res => {
                //console.log(res.data)
                //const longestAbsCountriesObject = res.data.reduce((maxObj, currentObj) => {
                //    return currentObj.abs_countries.length > maxObj.abs_countries.length ? currentObj : maxObj;
                //  }, res.data[0]);
                //message.error(`Countries: "${longestAbsCountriesObject.abs_countries.slice(0, -1)}" data are missing in calculation`)
                setSearhAggrResults(res.data)
                setLoading(false)
           })
    }

    const transformData = () => {
        //console.log("REGION")
        //console.log(searchRegionResults);
        //console.log("COUNTRY")
        //console.log(searchCountryResults);
        //console.log("WORLD")
        //console.log(searchWorldResults);
        //console.log(columns);

        const data = [...searchCountryResults, ...searchRegionResults, ...searchWorldResults];

        const uniqueRegions = new Set();

        // Step 3: Iterate over the merged data to collect unique regions/countries
        data.forEach(entry => {
            const { r_name, c_name } = entry;
            // Add country/region names to the set
            if (c_name) uniqueRegions.add(c_name);
            if (r_name) uniqueRegions.add(r_name);
        });

        //console.log(uniqueRegions)
        

        const formatted = [];
        const tempFormatted = [];
        const groupedData = {}; // To group entries by ind_code
        data.forEach(entry => {
            //console.log(entry);
            const { ind_code, u_name, d_year_first, d_year_last, r_name, c_name, d_value_first, d_value_last } = entry;
            // Use ind_code to group data by indicator
            if (!groupedData[ind_code]) {
                groupedData[ind_code] = {
                    ID: ind_code,
                    Indicator: ind_code,
                    Unit: u_name,
                    YearRange: `${d_year_last}-${d_year_first}`,
                    World: null,
                };
                
                // Initialize dynamic keys for unique regions/countries
                uniqueRegions.forEach(region => {
                    groupedData[ind_code][region] = null; // Initialize with null
                });
            }
        
            const regionOrCountryName = c_name || r_name;

            if (regionOrCountryName) {
                const isCountry = !!c_name;

                groupedData[ind_code][regionOrCountryName] = {
                    first: d_value_first,
                    last: d_value_last,
                    year_first: isCountry ? d_year_last : d_year_first,
                    year_last: isCountry ? d_year_first : d_year_last,
                };
            }
            setCsvData(groupedData)
            tempFormatted.push(groupedData)
        });
        //console.log(groupedData)
        const formatNumber = value => {
            if (value === null || value === undefined) return "NaN";
        
            const numericValue = parseFloat(value);
        
            if (isNaN(numericValue)) return value;
        
            if (numericValue < 100) {
                return numericValue.toFixed(1); 
            } else if (numericValue >= 100 && numericValue < 1000) {
                return Math.round(numericValue).toString(); 
            } else if (numericValue >= 1000) {
                return Math.round(numericValue).toLocaleString("en-US"); 
            }
        
            return value;
        };

        
        
        for (const key in groupedData) {
            const entry = groupedData[key];
            //console.log(entry)
            let entryToPush = {
                ID: entry.ID,
                Indicator: indicatorsState.value[entry.Indicator].ind_name_eng,
                Unit: entry.Unit,
                YearRange: entry.YearRange,
            } 

            for (const region of uniqueRegions) {
                //console.log(region);
                entryToPush[region] = (
                    <div>
                      <Tag color="green">
                        <Typography.Text>
                          <span style={{ fontWeight: "bold" }}>First Year Value ({entry[region].year_first}): </span>
                          {entry[region].first !== null && entry[region].first !== undefined 
                            ? formatNumber(Number(entry[region].first))
                            : 'NaN'}
                        </Typography.Text>
                      </Tag>
                  
                     
                  
                      <Tag color="red">
                        <Typography.Text>
                          <span style={{ fontWeight: "bold" }}>Last Year Value ({entry[region].year_last}): </span>
                          {entry[region].last !== null && entry[region].last !== undefined
                            ? formatNumber(Number(entry[region].last)) 
                            : 'NaN'}
                        </Typography.Text>
                      </Tag>
                    </div>
                  );
                  
                const isDuplicate = formatted.some(item => item.ID === entryToPush.ID); 
                if (!isDuplicate) {
                    formatted.push(entryToPush);
                } else {
                    //console.log("Duplicate entry found, not pushing:", entryToPush);
                }
            }
    
        }
        //console.log(formatted)
        return formatted;
    };
    

    const handleSummaryTableSearch = () => {
        const firstYearOfFirst = dateRangeFirstYear[0].year()
        const lastYearOfFirst = dateRangeFirstYear[1].year()
        const firstYearOfLast = dateRangeLastYear[0].year()
        const lastYearOfLast = dateRangeLastYear[1].year()

        const formattedRegions = [];
        setLoading(true);

        const firstYears = []
        firstYears.push(Number(firstYearOfFirst));
        firstYears.push(Number(lastYearOfFirst))
        const lastYears = []
        lastYears.push(Number(firstYearOfLast));
        lastYears.push(Number(lastYearOfLast));
        
        const regionCodes = selectedRegions.map(region => Number(region.code))
        const formattedIndicators = selectedIndicators.map(indicator => Number(indicator.split(' - ')[0]));
        const formattedCountries = selectedCountries.map(country => Number(country))

        //console.log(firstYears)
        //console.log(lastYears)
        //console.log(regionCodes)
        //console.log(formattedIndicators)
        //console.log(formattedCountries)

        //const columnList = ["ID","Indicator", "Unit", "YearRange", "World"];

        // Assuming selectedRegions and selectedCountries are defined and you have access to countriesState
        const columnList = [
            {
                title: "ID",
                dataIndex: "ID", // Change this according to your data structure
                key: "id", // Unique key for the column
            },
            {
                title: "Indicator",
                dataIndex: "Indicator", // Change this according to your data structure
                key: "indicator",
            },
            {
                title: "Unit",
                dataIndex: "Unit", // Change this according to your data structure
                key: "unit",
            },
            {
                title: "YearRange",
                dataIndex: "YearRange", // Change this according to your data structure
                key: "yearRange",
            },
            {
                title: "World",
                dataIndex: "World", // Change this according to your data structure
                key: "world",
            },
            ...selectedRegions.map((region) => ({
                title: region.name,
                dataIndex: region.name,
                key: region.name,
                render: (value) => {
                    const [fyv, lyv] = value.split('\n====\n'); // Split the value
                    return (
                        <>
                            <Tag color="blue">value</Tag>
                            <Tag color="red">{lyv}</Tag>
                        </>
                    );
                },
            })),
            ...selectedCountries.map((country) => {
                const countryName = countriesState.value[country].c_short_name_eng; // Get country name
                return {
                    title: countryName,
                    dataIndex: countryName, // Use country code or ID for dataIndex if available
                    key: country, // Ensure this is unique
                };
            }),
        ];


        //console.log(firstYears)
        //console.log(lastYears)
        //console.log(regionCodes)
        //console.log(formattedCountries)
        //console.log(formattedIndicators)
        //console.log(regionMappingsData)

        setSummaryTableColumns(columnList)

        let promises = [
            AxiosOICStat.post('/aggregations/calculate-first-last-year', { regions: regionCodes, indicators: formattedIndicators, first_year_range: firstYears, last_year_range: lastYears }),
            AxiosOICStat.post('/data/first-last-value', { countries: formattedCountries, indicators: formattedIndicators, first_year_range: lastYears, last_year_range: firstYears }),
            AxiosOICStat.post('/data/first-last-value', { countries: [0], indicators: formattedIndicators, first_year_range: firstYears, last_year_range: lastYears }),
        ]
        
        Promise.all(promises).then(([regionResponse, countryResponse, worldResponse]) => {
            const updatedRegionData = regionResponse.data.map((element, index) => {
                element.key = index;
                const matchingRegion = regionResponse.data.find(regionElement => regionElement.ind_code === element.ind_code);
                if (matchingRegion) {   
                    element.u_name = unitsState.value[matchingRegion.u_code].unit_name_eng;
                } else {
                    element.u_name = null;
                }
                return element;
            })
            //console.log(regionResponse.data)
            //console.log(updatedRegionData)
            setSearchRegionResults(updatedRegionData);
            //console.log(countryResponse.data)
            const updatedCountryData = countryResponse.data.map((element, index) => {
                element.key = index;
                //console.log(element)
                const matchingCountry = countryResponse.data.find(countryElement => countryElement.ind_code === element.ind_code);
                const related_ind = indicatorsState.value[matchingCountry.ind_code]
                const related_unit = unitsState.value[related_ind.unit_code]
                //console.log(matchingCountry)

                if (matchingCountry) {
                    element.u_name = related_unit.unit_name_eng;
                    element.c_name = countriesState.value[element.c_code].c_short_name_eng;
                } else {
                    element.u_name = null;
                }
                return element;
            });
            setSearchCountryResults(updatedCountryData);
            const updatedWorldData = worldResponse.data.map((element, index) => {
                element.key = index;

                const matchingRegion = regionResponse.data.find(regionElement => regionElement.ind_code === element.ind_code);

                if (matchingRegion) {
                    element.u_name = unitsState.value[matchingRegion.u_code].unit_name_eng;
                } else {
                    element.u_name = null;
                }
                element.c_name = "World";
                return element;
            });
            setSearchWorldResults(updatedWorldData);
            //setColumns(columnList);
            //console.log("RESULT")
            //console.log(transformedData)
            setLoading(false);           

        }).catch(error => {
            console.error("Error fetching data:", error);
            setLoading(false);
        });
        
       
    }

    useEffect(() => {
        if (searchRegionResults.length > 0 && searchCountryResults.length > 0 && searchWorldResults.length > 0 && summaryTableColumns.length > 0) {
            const transformedData = transformData();  // Call transformData when all the state data is available
            console.log("Transformed Data:", transformedData);
            setSearchSummaryTableResults(transformedData);  // Assuming you're storing the transformed data in the state
            setLoading(false);
        }
    }, [searchRegionResults, searchCountryResults, searchWorldResults]);  

    const exportToExcel = () => {
        const countryNameMapping = {};
        Object.values(countriesState.value).forEach(country => {
            countryNameMapping[country.c_code] = country.c_short_name_eng;
        });

        const indicatorNameMapping = {};
        Object.values(indicatorsState.value).forEach(indicator => {
            indicatorNameMapping[indicator.ind_code] = indicator.ind_name_eng;
        });

        // Create a new array for export with country names and indicator names
        const dataForExport = searchResults.map(({ key, c_code, ind_code, ...rest }) => ({
            "Country Code": c_code,
            "Country Name": countryNameMapping[c_code],
            "Indicator Code": ind_code,
            "Indicator Name": indicatorNameMapping[ind_code],
            "First year available": rest.d_year_first,
            "First year value": rest.d_value_first,
            "Last year available": rest.d_year_last,
            "Last year value": rest.d_value_last,
            "Percentage change": rest.percentage,
        }));

        // Create a new workbook and a worksheet with the formatted data
        const ws = XLSX.utils.json_to_sheet(dataForExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'TableData');

        // Download the workbook
        XLSX.writeFile(wb, 'table_data.xlsx');
    };

    const exportSummaryTableToCSV = (data, columns) => {
        columns = summaryTableColumns;
        data = csvData;  
    
        const dataArray = Object.values(data); 
        const headers = columns.map(col => col.title);
    
        const rows = dataArray.map(entry => 
          columns.map(col => {
            const value = entry[col.dataIndex]; 
            if (typeof value === 'object' && value !== null) {
              return `First: ${value.first ? Number(value.first).toFixed(3) : 'NaN'} Last: ${value.last ? Number(value.last).toFixed(3) : 'NaN'}`;
            } else {
              return value !== null && value !== undefined ? value : 'NaN'; 
            }
          })
        );
        rows.forEach((row, index) => {
            row[1] = indicatorsState.value[row[0]].ind_name_eng
            console.log(`Row ${index + 1}:`, row);
        });
        const csvContent = [
          headers.join(","),  
          ...rows.map(row => row.join(","))  
        ].join("\n"); 
        
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'data_export.csv'); 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); 
    };

    const handleCategoryChange = (categoryId, isChecked) => {
        console.log(isChecked);
        const indicatorsInCategory = Object.values(indicatorsState.value || {}).filter(
            (indicator) => indicator.cat_code === categoryId
        ).map((indicator) => `${indicator.ind_code} - ${indicator.ind_name_eng}`); // Match Select items' format
    
        if (isChecked) {
            // Add category and its indicators
            setSelectedCategories((prev) => [...prev, categoryId]);
            setSelectedIndicators((prev) => [...new Set([...prev, ...indicatorsInCategory])]);
        } else {
            // Remove category and its indicators
            setSelectedCategories((prev) => prev.filter((id) => id !== categoryId));
            setSelectedIndicators((prev) =>
                prev.filter((indicator) => !indicatorsInCategory.includes(indicator))
            );
        }
        console.log(selectedIndicators);
    };
    
    const categoriesCollapse = (
        <Collapse
            defaultActiveKey={['1']} // Keeps the collapse expanded by default
            bordered={false}
            style={{ backgroundColor: 'white' }}
        >
            <Collapse.Panel header="Categories" key="1">
                <Row gutter={[8, 8]}>
                    {Object.values(categoriesState.value || {})
                        .sort((a, b) => a.cat_name_eng.localeCompare(b.cat_name_eng)) // Sort categories alphabetically
                        .map((category, index) => (
                            <Col span={8} key={category.cat_code}> {/* Adjust span to divide into 3 columns */}
                                <Checkbox
                                    checked={selectedCategories.includes(category.cat_code)}
                                    onChange={(e) => handleCategoryChange(category.cat_code, e.target.checked)}
                                >
                                    {category.cat_name_eng}
                                </Checkbox>
                            </Col>
                        ))}
                </Row>
            </Collapse.Panel>
        </Collapse>
    );
    
      

    useEffect(() => {
        setSelectedCountries(selectedCountries.sort((a, b) => parseInt(a) - parseInt(b)));
        setSelectedIndicators(selectedIndicators.sort((a, b) => parseInt(a) - parseInt(b)));
    }, [selectedCountries, selectedIndicators]);

    const handleTabChange = (key) => {
        setActiveTabKey(key);
        setSearchResults([]);
      };

    //  const summaryTableColumns = [
    //  {
    //        title: 'Indicator Name',
    //        dataIndex: 'ind_name',
    //        key: 'ind_name',
    //  },  
    //  {
    //    title: 'Country Name',
    //    dataIndex: 'c_name',
    //    key: 'c_name',
    //    render: (text) => text || 'N/A',
    //  },
    //  {
    //    title: 'First Value',
    //    dataIndex: 'd_value_first',
    //    key: 'd_value_first',
    //  },
    //  {
    //    title: 'Last Value',
    //    dataIndex: 'd_value_last',
    //    key: 'd_value_last',
    //  },
    //  {
    //    title: 'First Year',
    //    dataIndex: 'd_year_first',
    //    key: 'd_year_first',
    //  },
    //  {
    //    title: 'Last Year',
    //    dataIndex: 'd_year_last',
    //    key: 'd_year_last',
    //  },
    //  
    //];

    const exampleWidths = {
        id: 100,
        indicator: 300,
        year: 100,
        world: 150,
        d_value: 100,
        d_value_first: 100,
        d_value_last: 100
      };

      const regions = [
        {
            id: 1,
            label: 'Regions',
            children: (
                <Row gutter={[8, 8]}>
                    {regionMappingsData && regionMappingsData.map((region) => (
                        <Col span={6} key={region.id}>
                            <Checkbox
                                checked={selectedRegions.some(item => item.code === region.id)}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setSelectedRegions([...selectedRegions, { code: region.id, name: region.region_name }]);
                                    } else {
                                        setSelectedRegions(selectedRegions.filter(item => item.code !== region.id));
                                    }
                                }}
                                value={region.id}
                            >
                                {region.region_name}
                            </Checkbox>
                        </Col>
                    ))}
                </Row>
            )
        }
    ];

    const tabs = [
        {
          key: "countries",
          label: "For Countries",
          children: <>
            {!auto_search &&
                <Col style={{ marginBottom: "10px" }}>
                    <Row flex="200px" style={{marginBottom:"10px"}}>
                        <Select
                            mode="multiple"
                            placeholder="Select Countries"
                            onChange={setSelectedCountries}
                            maxTagCount={2}
                            maxTagPlaceholder={renderTagPlaceholder}
                            style={{ width: '60%', height:"40px" }} // Adjust if necessary
                            showSearch
                        >
                            {Object.values(countriesState.value)
                                .filter(country => selectedCountries.includes(String(country.c_code)))
                                .concat(Object.values(countriesState.value).filter(country => !selectedCountries.includes(String(country.c_code))))
                                .map(country => <Option key={country.c_code}>{`${country.c_code} - ${country.c_short_name_eng}`}</Option>)}
                        </Select>
                    </Row>
                    {categoriesCollapse}
                    <br/>
                    <Row flex="auto" style={{marginBottom:"10px"}}>
                        <Select
                            mode="multiple"
                            placeholder="Select Indicators"
                            value={selectedIndicators}
                            onChange={setSelectedIndicators}
                            maxTagCount={3}
                            maxTagPlaceholder={renderTagPlaceholder}
                            style={{ width: '60%' }} // Adjust if necessary
                            showSearch
                        >
                            {Object.values(indicatorsState.value)
                                .filter(indicator => selectedIndicators.includes(String(indicator.ind_code)))
                                .concat(Object.values(indicatorsState.value).filter(indicator => !selectedIndicators.includes(String(indicator.ind_code))))
                                .map(indicator => <Option key={indicator.ind_code}>{`${indicator.ind_code} - ${indicator.ind_name_eng}`}</Option>)}
                        </Select>
                    </Row>
                    <Row>
                        <Col>
                            <strong style={{marginRight:"10px"}}>First Year Available:</strong>        
                            <RangePicker picker="year" onChange={setDateRangeFirstYear} />
                        </Col>
                        <Col>
                            <strong style={{marginRight:"10px", marginLeft:"10px"}}>Last Year Available:</strong>
                            <RangePicker picker="year" onChange={setDateRangeLastYear} />
                        </Col>
                                
                        <Col flex="none" style={{ paddingLeft: '25px'}}>
                            <Button type="primary" onClick={handleCountrySearch}>Search</Button>
                        </Col>
                    </Row>
                </Col>
            }
          <Row>
              <Col xs={24}>
                  <DataTable data={searchResults} columns={["c_code", "ind_name", "d_year_first", "d_value_first", "d_year_last", "d_value_last"]} widths={['10%', '40%', '10%', '10%', '10%', '10%', '10%']}
                      loading={loading} calculatePercentage={true} percentageCols={["d_value_first", "d_value_last"]} decimalSymbol={"."} decimalPlaces={3}/>
              </Col>
          </Row>
          <br/>
          <Button onClick={exportToExcel} type="primary" style={{ marginLeft: '10px' }}>
              Export Table
          </Button>
      </>,
        },
        {
          key: "regions",
          label: "For Regions",
          children: <>
            {!auto_search &&
                <Col style={{ marginBottom: "10px"}}>
                    <Row flex="200px" style={{marginBottom:"10px"}}>
                    <Collapse size="small" items={regions} bordered={false} style={{ marginBottom: "10px", width: "60%", backgroundColor: "white"}} />
                    </Row>
                    {categoriesCollapse}
                    <br/>
                    <Row flex="auto" style={{marginBottom:"10px"}}>
                        <Select
                            mode="multiple"
                            placeholder="Select Indicators"
                            value={selectedIndicators}
                            onChange={setSelectedIndicators}
                            maxTagCount={3}
                            maxTagPlaceholder={renderTagPlaceholder}
                            style={{ width: '60%'}} // Adjust if necessary
                            showSearch
                        >
                            {Object.values(indicatorsState.value)
                                .filter(indicator => selectedIndicators.includes(String(indicator.ind_code)))
                                .concat(Object.values(indicatorsState.value).filter(indicator => !selectedIndicators.includes(String(indicator.ind_code))))
                                .map(indicator => <Option key={indicator.ind_code}>{`${indicator.ind_code} - ${indicator.ind_name_eng}`}</Option>)}
                        </Select>
                    </Row>
                    <Row>
                        <Col>
                            <strong style={{marginRight:"10px"}}>First Year Available:</strong>        
                            <RangePicker picker="year" onChange={setDateRangeFirstYear} />
                        </Col>
                        <Col>
                            <strong style={{marginRight:"10px", marginLeft:"10px"}}>Last Year Available:</strong>
                            <RangePicker picker="year" onChange={setDateRangeLastYear} />
                        </Col>
                                
                        <Col flex="none" style={{ paddingLeft: '25px'}}>
                            <Button type="primary" onClick={handleRegionSearch}>Search</Button>
                        </Col>
                    </Row>
                </Col>
            }
          <Row>
              <Col xs={24}>
                <DataTable className={"custom-table"} data={searchAggrResults} columns={["r_name", "ind_name", "d_year_first", "d_value_first", "d_year_last", "d_value_last"]} widths={['15%', '55%', '10%', '10%']} loading={loading}
                            colYearView={false} decimalSymbol={"."} decimalPlaces={3}/> 
              </Col>
          </Row>
          <br/>
          <Button onClick={exportToExcel} type="primary" style={{ marginLeft: '10px' }}>
              Export Table
          </Button>
          </>,
        },
        {
            key: "summary",
            label: "Summary Table",
            children: <>
                {!auto_search &&
                <Col style={{ marginBottom: "10px"}}>
                    <Row>
                    <Collapse size="small" items={regions} bordered={false} style={{ marginBottom: "10px", width: "60%", backgroundColor: "white"}} />   
                    </Row>
                    {categoriesCollapse}
                    <br/>
                    <Row flex="200px" style={{marginBottom:"10px"}}>
                        <Select
                            mode="multiple"
                            placeholder="Select Countries"
                            onChange={setSelectedCountries}
                            maxTagCount={2}
                            maxTagPlaceholder={renderTagPlaceholder}
                            style={{ width: '60%', height:"40px" }} // Adjust if necessary
                            showSearch
                        >
                            {Object.values(countriesState.value)
                                .filter(country => selectedCountries.includes(String(country.c_code)))
                                .concat(Object.values(countriesState.value).filter(country => !selectedCountries.includes(String(country.c_code))))
                                .map(country => <Option key={country.c_code}>{`${country.c_code} - ${country.c_short_name_eng}`}</Option>)}
                        </Select>
                    </Row>
                    <Row flex="auto" style={{marginBottom:"10px"}}>
                        <Select
                            mode="multiple"
                            placeholder="Select Indicators"
                            onChange={setSelectedIndicators}
                            value={selectedIndicators}
                            maxTagCount={3}
                            maxTagPlaceholder={renderTagPlaceholder}
                            style={{ width: '60%' }} // Adjust if necessary
                            showSearch
                        >
                            {Object.values(indicatorsState.value)
                                .filter(indicator => selectedIndicators.includes(String(indicator.ind_code)))
                                .concat(Object.values(indicatorsState.value).filter(indicator => !selectedIndicators.includes(String(indicator.ind_code))))
                                .map(indicator => <Option key={indicator.ind_code}>{`${indicator.ind_code} - ${indicator.ind_name_eng}`}</Option>)}
                        </Select>
                    </Row>
                    
                    <Row>
                        <Col>
                            <strong style={{marginRight:"10px"}}>First Year Available:</strong>        
                            <RangePicker picker="year" onChange={setDateRangeFirstYear} />
                        </Col>
                        <Col>
                            <strong style={{marginRight:"10px", marginLeft:"10px"}}>Last Year Available:</strong>
                            <RangePicker picker="year" onChange={setDateRangeLastYear} />
                        </Col>
                                
                        <Col flex="none" style={{ paddingLeft: '25px'}}>
                            <Button type="primary" onClick={handleSummaryTableSearch}>Search</Button>
                        </Col>
                    </Row>
                </Col>
            }
          <Row>
              <Col xs={24}>
              <SummaryDataTable
                  columns={summaryTableColumns}
                  data={searchSummaryTableResults}
                  colYearView={true}
                  calculatePercentage={true}
                  decimalSymbol=","
                  decimalPlaces={3}
                  loading={loading}
                  firstLastYear={true}
                  isDataFirstLastYear={true}
                  />
              </Col>
          </Row>
          <br/>
          <Button onClick={exportSummaryTableToCSV} type="primary" style={{ marginLeft: '10px' }}>
              Export Table
          </Button>
            </>,
        },
    ]

    return (
        <Tabs
        activeKey={activeTabKey}
        onChange={handleTabChange}
        items={tabs}
        >
        </Tabs>
    );
};